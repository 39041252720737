<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-data-table
        class="elevation-1 mt-5"
        v-if="data!=null && !api.isLoading"
        :items="data"
        :search="searchResult"
        :footer-props="{
            showFirstLastPage:true,
        }"
        :headers="headers">
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" color=" py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="text-h4 font-weight-bold">
                                    Proof of Payment
                                </v-toolbar-title>
                            </v-row>
                            <v-divider>
                            </v-divider>
                            <v-row class="mt-3">
                                <v-text-field
                                dense
                                outlined
                                clearable
                                v-model="searchResult"
                                label="search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <AError :api="api"/>
            </template>
            <template v-slot:item.Index="{item}">
                {{ data.indexOf(item)+1 }}
            </template> 
            <template v-slot:item.name="{item}">
                <!-- <a v-if="item.companies_id!=33" @click="redirectToCompany(data[data.indexOf(item)].companies_id)" class="text-decoration-none">
                {{ item.name }}
                </a> -->
                <div v-if="allowedRolesRedirectCompanies.includes($store.getters.getRole)">

                    <router-link v-if="item.companies_id!=33" :to="{name:'PageCompaniesDetail',params:{id:data[data.indexOf(item)].companies_id}}">
                        {{ item.name }}
                    </router-link>
                    <span v-else>
                        {{ item.name }} (Internal)
                    </span>
                </div>

                <div v-else>
                    <span v-if="item.companies_id!=33">
                        {{ item.name }}
                    </span>
                    <span v-else>
                        {{ item.name }} (Internal)
                    </span>
                </div>

            </template>
            <template v-slot:item.fname="{item}">
                {{ item.fname }} {{ item.lname }}
            </template>
            <template v-slot:item.created_at="{item}">
                <!--x {{ item.created_at }} -->
                {{ item.created_at.split("T")[0] }}  
                {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}}
            </template>
            <template v-slot:item.delete="{item}">
                <v-btn color="red" plain @click="validateInput(item.attachment_id)">Delete</v-btn>
            </template>
            <template v-slot:item.attachment_name="{item}">
                <a :href="item.attachment_url" target="_blank" download>
                    {{ item.attachment_name }}
                </a>
                <!-- <a @click="redirectDetail(item.form_id)">
                    {{ item.attachment_name }}
                </a> -->
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AError from '../../components/common/AError.vue';
import AConfirmation from '../../components/common/AConfirmation.vue';
export default {
    components:{
    AError,
    AConfirmation
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        attachment:false,
        searchResult:null,
        isPending:false,
        data:null,
        allowedRolesRedirectCompanies : [ "developer","vision_admin","operation","sales_manager"],
        headers:[
            {
                text:'#',
                value:"Index",
                align:"start",
                sortable:false,
            },
            {
                text:'File',
                value:'attachment_name',
            },
            {
                text:'Company',
                value:'name',
            },
            {
                text:'PIC',
                value:'fname'
            },
            {
                text:'Date',
                value:'created_at',
            },
    ],
    api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
    }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="readAttachmentsBasedOnType"){
                this.data = resp.data;
            }
            if(resp.class=="deleteAttachment"){
                location.reload();
            
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetchReceipt(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/form/attachment/type/Receipt";
            return tempApi;
        },
        fetch(){
            let fetchReceiptApi = this.fetchReceipt();
            this.$api.fetch(fetchReceiptApi);
        },
        redirectToCompany(companyId){
            // this.$store.commit("updateCompany",companyId);
            let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':companyId}});
            window.open(route.href,'_blank');
        },
        validateInput(attachmentId){
            this.isPending = true;
            this.attachment = attachmentId;
        },
        submit(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "DELETE";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/attachment/"+this.attachment;
            tempApi.params= {
                'userEmail':this.$store.getters.getEmail,
            };  
            this.$api.fetch(tempApi);
        },
        cancelSubmit(){
            this.attachment = null;
            this.isPending = false;
            return 1;
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
            // return this.$moment(time,'Asia/Kuala_Lumpur').format("DD MMMM YYYY HH:mm:ss");
            // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
        },
    
        // redirectDetail(formId){
        //     this.$store.commit("updateForm",formId);
        //     this.$router.push({name:'PageReceiptDetail'});
        // }

    }
}
</script>